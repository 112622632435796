<template>
  <div class="wrapper-boards">
    <div id="boards-desktop">
      <board-card-detail
        v-for="board in BOARDCARDS.DATA"
        :id="board.id"
        :key="board.id"
        :img="setImg(board.img)"
        :title="board.title"
        :name="board.name"
      />
    </div>
    <div id="boards-mobile">
      <carousel
        :name="carouselName"
        :size="0"
        :total-all-data="BOARDCARDS.DATA.length"
      >
        <template #item>
          <div
            v-for="(board, index) in BOARDCARDS.DATA"
            :id="`${carouselName}-${index+1}`"
            :key="`${index}-key`"
            class="slide-number"
            style="margin-right: 20px;"
          >
            <board-card-detail
              :id="board.id"
              :key="board.id"
              :img="setImg(board.img)"
              :title="board.title"
              :name="board.name"
            />
          </div>
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import BoardCardDetail from './BoardCardDetail';
import Carousel from '../../../components/new-carousel/Carousel';
import BOARDCARDS from '../app/constants/boardCards';

export default {
  name: 'BoardCard',
  components: {
    Carousel,
    BoardCardDetail,
  },
  data() {
    return {
      BOARDCARDS,
      carouselName: 'board',
    };
  },
  methods: {
    setImg(img) {
      return img ? `/images/about/${img}.webp` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style.scss";


.wrapper-boards {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

#boards-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1000px;
  margin: auto;
  gap: 20px;
  margin-bottom: 40px;

  @include for-size(mobile) {
    display: none;
  }

	@media (min-width: 600px) and (max-width: 1000px) {
    width: 100%;
	}

  @media (min-width: 1280px){
    width: 100%;
	}
}

#boards-mobile {
  display: none;
  @include for-size(mobile) {
    display: block;
    margin-left: 20px;
  }
}

.slide-number {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}
</style>
