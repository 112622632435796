const DATA = [
  {
    id: 'ceo',
    img: 'farian',
    name: 'Farian Kirana',
    title: 'Chief Executive Officer',
  },
  {
    id: 'cso',
    img: 'arif',
    name: 'Arif Wibowo',
    title: 'Chief Sales Officer',
  },
  {
    id: 'ccno',
    img: 'victor',
    name: 'Victor A. Subekti',
    title: 'Chief Compliance and Network Officer',
  },
  {
    id: 'cmo',
    img: 'kenny',
    name: 'Kenny Kwanto',
    title: 'Chief Marketing Officer',
  },
  {
    id: 'cro',
    img: 'adwina',
    name: 'Adwina Marisa',
    title: 'Chief Retail Officer',
  },
  {
    id: 'coo',
    img: 'fadli',
    name: 'Mohammad Fadli',
    title: 'Chief Operating Officer',
  },
  {
    id: 'cxo',
    img: 'budi',
    name: 'Budi Santoso',
    title: 'Chief Experience Officer',
  },
];

module.exports = { DATA };
