const DATA2021 = [
  {
    rewardCardsId: '20211',
    rewardCardsTitle:
      'The Best Digital Media Gold Category in The Best Contact Center Indonesia 2021 by Indonesia Contact Centre Association',
    rewardCardsPhoto: '2021reward1.webp',
    rewardCardsThumbnail: '2021reward1_thumb.webp',
    rewardCardsMobile: '2021reward1_mobile.svg',
    rewardCardsMobileThumb: '2021reward1_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20212',
    rewardCardsTitle:
      'Marketeer of The Year 2021 by Markplus Inc. for Kenny Kwanto, CMO Lion Parcel',
    rewardCardsPhoto: '2021reward2.webp',
    rewardCardsThumbnail: '2021reward2_thumb.webp',
    rewardCardsMobile: '2021reward2_mobile.svg',
    rewardCardsMobileThumb: '2021reward2_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20213',
    rewardCardsTitle:
      "Impactful Digital Marketing Campaign of the Year for JAGOPACK at Marketeers Editor's Choice Award 2021 by Marketeers",
    rewardCardsPhoto: '2021reward3.webp',
    rewardCardsThumbnail: '2021reward3_thumb.webp',
    rewardCardsMobile: '2021reward3_mobile.svg',
    rewardCardsMobileThumb: '2021reward3_mobile_thumb.svg',
  },
];

const DATA2022 = [
  {
    rewardCardsId: '20221',
    rewardCardsTitle: 'Marketeers OMNI Brands of The Year 2022 for JAGOPACK',
    rewardCardsPhoto: '2022reward1.webp',
    rewardCardsThumbnail: '2022reward1_thumb.webp',
    rewardCardsMobile: '2022reward1_mobile.svg',
    rewardCardsMobileThumb: '2022reward1_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20222',
    rewardCardsTitle:
      'Forbes 30 Under 30 Year of 2022 for Farian Kirana, CEO Lion Parcel',
    rewardCardsPhoto: '2022reward2.webp',
    rewardCardsThumbnail: '2022reward2_thumb.webp',
    rewardCardsMobile: '2022reward2_mobile.svg',
    rewardCardsMobileThumb: '2022reward2_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20223',
    rewardCardsTitle:
      'Prestige 40 Under 40 Year of 2022 for Farian Kirana, CEO Lion Parcel',
    rewardCardsPhoto: '2022reward3.webp',
    rewardCardsThumbnail: '2022reward3_thumb.webp',
    rewardCardsMobile: '2022reward3_mobile.svg',
    rewardCardsMobileThumb: '2022reward3_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20224',
    rewardCardsTitle:
      'EY Entrepreneur of The Year 2022 Finalist for Farian Kirana, CEO Lion Parcel',
    rewardCardsPhoto: '2022reward4.webp',
    rewardCardsThumbnail: '2022reward4_thumb.webp',
    rewardCardsMobile: '2022reward4_mobile.svg',
    rewardCardsMobileThumb: '2022reward4_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20225',
    rewardCardsTitle:
      'Best of The Best Customer Service Team at Indonesia Customer Service Awards 2022 by SWA Media Group and Business Digest',
    rewardCardsPhoto: '2022reward5.webp',
    rewardCardsThumbnail: '2022reward5_thumb.webp',
    rewardCardsMobile: '2022reward5_mobile.svg',
    rewardCardsMobileThumb: '2022reward5_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20226',
    rewardCardsTitle:
      'Best of The Best Digital Marketing Team at Indonesia Digital Marketing Awards 2022 by SWA Media Group and Business Digest',
    rewardCardsPhoto: '2022reward6.webp',
    rewardCardsThumbnail: '2022reward6_thumb.webp',
    rewardCardsMobile: '2022reward6_mobile.svg',
    rewardCardsMobileThumb: '2022reward6_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20227',
    rewardCardsTitle:
      'TOP Digital Implementation 2022 Level Stars 3 by IT Works Magazine',
    rewardCardsPhoto: '2022reward7.webp',
    rewardCardsThumbnail: '2022reward7_thumb.webp',
    rewardCardsMobile: '2022reward7_mobile.svg',
    rewardCardsMobileThumb: '2022reward7_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20228',
    rewardCardsTitle:
      'TOP Leader on Digital Implementation 2022 for Farian Kirana, CEO Lion Parcel by IT Works Magazine',
    rewardCardsPhoto: '2022reward8.webp',
    rewardCardsThumbnail: '2022reward8_thumb.webp',
    rewardCardsMobile: '2022reward8_mobile.svg',
    rewardCardsMobileThumb: '2022reward8_mobile_thumb.svg',
  },
];

const DATA2023 = [
  {
    rewardCardsId: '20231',
    rewardCardsTitle:
      '40 Under 40 Asia HR Leaders by Darwinbox for Angelina Siahaan, Head of People Lion Parcel',
    rewardCardsPhoto: '2023reward1.webp',
    rewardCardsThumbnail: '2023reward1_thumb.webp',
    rewardCardsMobile: '2023reward1_mobile.svg',
    rewardCardsMobileThumb: '2023reward1_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20232',
    rewardCardsTitle:
      'Fortune 40 Under 40 Year of 2023 for Farian Kirana, CEO Lion Parcel',
    rewardCardsPhoto: '2023reward2.webp',
    rewardCardsThumbnail: '2023reward2_thumb.webp',
    rewardCardsMobile: '2023reward2_mobile.svg',
    rewardCardsMobileThumb: '2023reward2_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20233',
    rewardCardsTitle:
      'Most Innovative Use of Cloud, Platforms and Ecosystems in FutureCFO Excellence Awards 2023',
    rewardCardsPhoto: '2023reward3.webp',
    rewardCardsThumbnail: '2023reward3_thumb.webp',
    rewardCardsMobile: '2023reward3_mobile.svg',
    rewardCardsMobileThumb: '2023reward3_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20234',
    rewardCardsTitle:
      'Top 20 Franchisee Satisfaction 2023 Category Jasa Kurir by Majalah Franchise Indonesia',
    rewardCardsPhoto: '2023reward4.webp',
    rewardCardsThumbnail: '2023reward4_thumb.webp',
    rewardCardsMobile: '2023reward4_mobile.svg',
    rewardCardsMobileThumb: '2023reward4_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20235',
    rewardCardsTitle:
      'Indonesia Branding Campaign of the Year 2023 category Digital Marketing by MarkPlus, Inc.',
    rewardCardsPhoto: '2023reward5.webp',
    rewardCardsThumbnail: '2023reward5_thumb.webp',
    rewardCardsMobile: '2023reward5_mobile.svg',
    rewardCardsMobileThumb: '2023reward5_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20236',
    rewardCardsTitle:
      'Indonesia Digital Customer Engagement Champion 2023 category Services Industry by SWA Media Group',
    rewardCardsPhoto: '2023reward6.webp',
    rewardCardsThumbnail: '2023reward6_thumb.webp',
    rewardCardsMobile: '2023reward6_mobile.svg',
    rewardCardsMobileThumb: '2023reward6_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20237',
    rewardCardsTitle:
      "Viral Social Media Campaign of the Year in Marketeers Editor's Choice Awards 2023. by Marketeers",
    rewardCardsPhoto: '2023reward7.webp',
    rewardCardsThumbnail: '2023reward7_thumb.webp',
    rewardCardsMobile: '2023reward7_mobile.svg',
    rewardCardsMobileThumb: '2023reward7_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20238',
    rewardCardsTitle:
      'The Best Digital Media Platinum Category in The Best Contact Center Indonesia 2023 by Indonesia Contact Centre Association',
    rewardCardsPhoto: '2023reward8.webp',
    rewardCardsThumbnail: '2023reward8_thumb.webp',
    rewardCardsMobile: '2023reward8_mobile.svg',
    rewardCardsMobileThumb: '2023reward8_mobile_thumb.svg',
  },
  {
    rewardCardsId: '20239',
    rewardCardsTitle:
      'Logistics Platform of the Year in Indonesia Logistics Awards 2023 by Supply Chain Indonesia',
    rewardCardsPhoto: '2023reward9.webp',
    rewardCardsThumbnail: '2023reward9_thumb.webp',
    rewardCardsMobile: '2023reward9_mobile.svg',
    rewardCardsMobileThumb: '2023reward9_mobile_thumb.svg',
  },
];

module.exports = { DATA2021, DATA2022, DATA2023 };
