const DATA = [
  {
    id: 'ceo',
    imgContent: 'farian',
    img: 'farian',
    name: 'Farian Kirana',
    title: '<b>CEO</b> <i>(Chief Executive Officer)</i>',
    shortTitle: 'CEO',
    desc: 'Sejak tahun 2017, Farian Kirana telah menjabat sebagai Chief Executive Officer (CEO) di Lion Parcel dan memimpin lebih dari 1.000 karyawan untuk memberikan layanan pengiriman yang murah, aman, dan terpercaya bagi masyarakat Indonesia. Sejak masa kepemimpinannya, Lion Parcel telah mengalami perkembangan yang pesat dengan memperluas jangkauan pengirimannya menjangkau hingga 98% area di Tanah Air dengan kolaborasi hebat bersama lebih dari 7.000 agen, 15.000 kurir pengiriman, dan 3.000 armada yang saling terintegrasi satu sama lain.<br><br>Beliau telah memimpin perusahaan dengan gaya kepemimpinannya yang sangat terbuka, namun secara kreatif terus berinovasi meluncurkan program untuk menjawab tuntutan dan kebutuhan pasar. Didukung oleh inisiatif digitalisasi yang berkelanjutan untuk mengikuti perkembangan tren saat ini, beliau telah berhasil mendongkrak kinerja perusahaan, membawa Lion Parcel mengikuti perkembangan industri logistik, dan membangunnya menjadi praktik bisnis yang lebih berkelanjutan dengan pertumbuhan perusahaan yang positif selama tiga tahun terakhir.<br><br>Beliau memulai karir profesionalnya di Lion Parcel sebagai Technical Advisor pada tahun 2015 setelah menuntaskan pendidikan sarjana S1-nya di Universitas Pelita Harapan tahun 2014 di jurusan Business Administration and Management.',
  },
  {
    id: 'cso',
    imgContent: 'arif',
    img: 'arif',
    name: 'Arif Wibowo',
    title: '<b>CSO</b> <i>(Chief Sales Officer)</i>',
    shortTitle: 'CSO',
    desc: 'Arif Wibowo bergabung bersama Lion Parcel sejak tahun 2019 sebagai Chief Sales Officer. Dengan pengalaman lebih dari 20 tahun di bidang sales dan 10 tahun diantaranya di dalam industri kurir dan logistik Tanah Air saat ini, beliau memimpin tim Corporate Sales Lion Parcel dan berkontribusi terhadap peningkatan jumlah dan revenue pelanggan korporat yang signifikan sejak bergabung di tahun 2019.<br><br>Beliau telah menyelesaikan pendidikan D4 dalam Manajemen Transportasi Udara di STMT Trisakti pada tahun 1999. Beliau telah menghabiskan lebih dari tujuh tahun untuk memperdalam pengalamannya di bidang sales industri logistik. Sebelum bergabung dengan Lion Parcel, ragam penghargaan pun telah didapatkan atas kinerjanya yang cemerlang, mulai dari Sales of The Year pada tahun 2013 dan 2014, hingga Marketer of The Year Jabodetabek 2016 dari Markplus Inc.',
  },
  {
    id: 'ccno',
    imgContent: 'victor',
    img: 'victor',
    name: 'Victor A. Subekti',
    title: '<b>CCNO</b> <i>(Chief Compliance and Network Officer)</i>',
    shortTitle: 'CCNO',
    desc: 'Victor Ary Subekti telah bergabung bersama Lion Parcel sejak tahun 2015 sebagai GM Commercial dan kini menjabat sebagai Chief Compliance and Network Officer (CCNO). Beliau bertanggung jawab untuk memastikan bahwa keseluruhan operasional secara internal maupun eksternal telah sesuai dengan regulasi dan peraturan yang berlaku. Selain itu, fokus utama divisi yang beliau pimpin juga terkait dengan perluasan jaringan operasional Lion Parcel dan hubungan pemerintah.<br><br>Sudah berkecimpung di industri pengiriman ekspress dan logistik selama 25 tahun dan telah menduduki berbagai posisi strategis di level manajemen. Meraih gelar Magister Bisnis Administrasi dari NTU Singapura di Tahun 2007, beliau melanjutkan studi Hukum di Universitas Pancasila.',
  },
  {
    id: 'cmo',
    imgContent: 'kenny',
    img: 'kenny',
    name: 'Kenny Kwanto',
    title: '<b>CMO</b> <i>(Chief Marketing Officer)</i>',
    shortTitle: 'CMO',
    desc: 'Kenny Kwanto bergabung dengan Lion Parcel pada Januari 2021 sebagai Chief of Marketing (CMO). Sejak itu, beliau telah memimpin seluruh inisiatif pemasaran yang dilakukan oleh Lion Parcel. Inisiatif-inisiatif tersebut telah berkontribusi pada pertumbuhan merek dan bisnis Lion Parcel secara signifikan di industri logistik Indonesia. Terbukti sebelum genap satu tahun bergabung bersama Lion Parcel, beliau telah dinobatkan sebagai Industry Marketing Champion 2021 for the Logistics Sector dari Markplus,Inc. melalui Marketeer of the Year 2021.<br><br>Menyelesaikan pendidikan S2-nya sebagai Magister Manajemen di Institut Bisnis dan Informatika Indonesia pada tahun 2010, beliau telah memiliki pengalaman karir profesional di berbagai industri, mulai dari FMCG, <i>luxury goods</i>, hingga sembilan tahun berkecimpung di industri telekomunikasi dan berkontribusi pada pertumbuhan perusahaan dengan strategi pemasaran yang holistik dan memperhatikan target pasar yang tepat.',
  },
  {
    id: 'cro',
    imgContent: 'adwina',
    img: 'adwina',
    name: 'Adwina Marisa',
    title: '<b>CRO</b> <i>(Chief Retail Officer)</i>',
    shortTitle: 'CRO',
    desc: 'Adwina Marisa atau yang biasa akrab disapa Kitty bergabung bersama Lion Parcel sebagai Chief Retail Officer pada April 2021. Sejak saat itu, beliau memimpin seluruh program dan aktivitas Lion Parcel yang berhubungan dengan strategi komersial dengan beragam inisiatif khusus bertujuan meningkatkan transaksi retail pelanggan.<br><br>Beliau telah memiliki pengalaman profesional lebih dari 20 tahun dalam bidang komersial, <i>supply chain</i>, <i>partnership</i>, hingga <i>business development</i> dalam berbagai industri, mulai dari <i>financial technology (fintech)</i>, telekomunikasi, hingga FMCG. Dalam segi pendidikan, beliau meraih gelar Magister Administrasi Bisnis di Institut Teknologi Bandung pada tahun 2003.',
  },
  {
    id: 'coo',
    imgContent: 'fadli',
    img: 'fadli',
    name: 'Mohammad Fadli',
    title: '<b>COO</b> <i>(Chief Operating Officer)</i>',
    shortTitle: 'COO',
    desc: 'Mohammad Fadli bergabung kembali bersama Lion Parcel sejak tahun 2020 sebagai VP of Logistic Operation dan kini menjabat sebagai Chief Operating Officer (COO) di Lion Parcel. Dengan pengalaman karir profesionalnya selama lebih dari 20 tahun di industri logistik Tanah Air, beliau kini bertanggung jawab terhadap kelangsungan operasional sehari-hari di Lion Parcel.<br><br>Fadli menyelesaikan pendidikan S1-nya di bidang Hubungan Masyarakat, Universitas Padjadjaran pada tahun 1999. Meski memulai karirnya sebagai telemarketer, beliau telah memiliki ketertarikan dan antusiasme yang tinggi terhadap bidang operasional sedari dulu dan mulai berkarir di industri logistik pada tahun 2002. Beliau juga sempat bergabung sebagai Area Operations Manager di Lion Parcel pada tahun 2013-2017 sebelum kembali lagi sebagai VP Logistic Lion Parcel di tahun 2020.',
  },
  {
    id: 'cxo',
    imgContent: 'budi',
    img: 'budi',
    name: 'Budi Santoso',
    title: '<b>CXO</b> <i>(Chief Experience Officer)</i>',
    shortTitle: 'CXO',
    desc: 'Budi Santoso bergabung bersama Lion Parcel sejak awal tahun 2022 sebagai Chief Experience Officer (CXO). Dengan pengalaman profesionalnya selama lebih dari 16 tahun di bidang operasional dan <i>customer service</i>, beliau kini bertanggung jawab untuk memastikan kelancaran keseluruhan pengalaman pelanggan Lion Parcel secara <i>end-to-end</i>.<br><br>Budi menyelesaikan pendidikannya di Universitas Islam Bandung kemudian memulai perjalanan karir profesionalnya di industri telekomunikasi selama lebih dari lima tahun dan terus memperdalam wawasan terkait pengalaman pelanggan hingga <i>customer service</i> dari berbagai industri lainnya seperti platform media penyiaran hingga <i>e-commerce</i>.',
  },
];

module.exports = { DATA };
