<template>
  <div class="about-section">
    <BreadCrumb
      :class="['top-left', { 'image-default__breadcrumb' : isErrorImage }]"
      :is-use-route-name="true"
    />

    <!-- Banner -->
    <div class="about-section__banner do-flex">
      <div :class="['about-section__banner__text do-flex-col', { 'image-default__about-header' : isErrorImage }]">
        <div class="about-section__banner__text__title">
          Kiriman Anda
        </div>
        <div class="about-section__banner__text__desc">
          Prioritas Kami
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBanner"
        alt="about_banner.png"
        @error="(event) => setErrorImage(event, 'about-banner')"
      />
    </div>
    <!-- Banner -->

    <!-- About Info -->
    <div class="about-section__info do-flex">
      <div class="about-section__info__text do-flex-col">
        <div class="about-section__info__text__title">
          Tentang Lion Parcel
        </div>
        <div class="about-section__info__text__desc">
          Lion Parcel (PT Lion Express) merupakan perusahaan logistik yang berdiri sejak 14 Februari 2013 melayani <em>first</em>, <em>middle</em>, dan <em>last mile</em> baik untuk pengiriman domestik maupun internasional.
          <br><br>Didukung oleh lebih dari 7.000 agen, 15.000 kurir pengiriman, dan 3.000 armada, jaringan pengiriman Lion Parcel saat ini menjangkau 98% kecamatan di 34 provinsi di Indonesia. Lion Parcel juga didukung oleh jaringan dan infrastruktur Lion Group untuk menghubungkan masyarakat tanpa batasan waktu dan jarak guna memberikan layanan logistik yang terjangkau.
        </div>
      </div>
      <div class="about-section__info__img">
        <ImageLazy
          :src="`/images/about/ill_tentang_kami.svg`"
          alt="about_info.png"
        />
      </div>
    </div>
    <!-- About Info -->

    <!-- Visi Misi -->
    <div class="about-section__vm do-flex">
      <ImageLazy
        :src="`/images/about/stand-lion-parcel.webp`"
        alt="about_stand.png"
        class="about-section__vm__img"
        @error="(event) => setErrorImage(event, 'about-vm')"
      />
      <div class="about-section__vm__text do-flex-col">
        <div class="about-section__vm__text__title">
          Visi
        </div>
        <div class="about-section__vm__text__desc1">
          Menjadi perusahaan logistik dengan layanan yang <em>seamless</em> dan dapat diandalkan.
        </div>
        <div class="about-section__vm__text__title">
          Misi
        </div>
        <div class="about-section__vm__text__desc2">
          Berkomitmen untuk terus memberikan inovasi yang bisa mengembangkan setiap layanan perusahaan untuk memberikan layanan yang terjangkau untuk pengalaman pelanggan terbaik.
        </div>
      </div>
    </div>
    <!-- Visi Misi -->

    <!-- Value -->
    <div class="about-section__sub">
      <div class="about-section__sub__wrap">
        <div class="about-section__sub__wrap__title">
          Nilai Perusahaan
        </div>
        <div class="about-section__sub__wrap__line">
          <img
            :src="`/images/about/line.png`"
            alt="line.png"
          >
        </div>
      </div>
    </div>
    <div class="about-section__value">
      <div
        v-for="values in companyValues"
        :key="values.title"
        class="about-section__value__text"
      >
        <ImageLazy
          :src="setImg(values.img)"
          class="imgValue"
          alt="value.png"
          @error="(event) => setErrorImage(event, 'value-company')"
        />
        <div class="about-section__value__text__title">
          <img
            class="ico"
            :src="setImg(values.ico)"
            alt="rise-ico.png"
          >
          {{ values.title }}
        </div>
        <div class="about-section__value__text__desc">
          {{ values.desc }}
        </div>
      </div>
    </div>
    <!-- Value -->

    <!-- Board -->
    <div class="about-section__board do-flex-col">
      <div class="about-section__board__title">
        Pimpinan Kami
      </div>
      <BoardCard />
    </div>
    <!-- Board -->

    <!-- Reward -->
    <div class="about-section__sub2">
      <div class="about-section__sub2__title">
        Penghargaan
      </div>
    </div>
    <div
      id="button-tab"
      class="about-section__button-tab"
    >
      <div
        v-for="i of 3"
        :key="i"
        class="button-tab-year"
        :class="{ active: i == 3 }"
        @click="changeYear(`202${i}`)"
      >
        {{ `202${i}` }}
      </div>
    </div>
    <div class="about-section__reward">
      <RewardCard
        v-for="(post, rewardCardsId) in dataSource"
        :key="post.rewardCardsId + rewardCardsId"
        :post="post"
      />
    </div>
    <!-- Reward -->
  </div>
</template>

<script>
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import BoardCard from './components/BoardCard.vue';
import RewardCard from './components/RewardCard.vue';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import REWARDCARDS from './app/constants/rewardCards';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'About',
  components: {
    BoardCard,
    RewardCard,
    BreadCrumb,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_about', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      REWARDCARDS,
      dataSource: REWARDCARDS.DATA2023,
      isErrorImage: false,
      companyValues: [
        {
          title: 'Rise to Every Challenge',
          desc: 'Melihat setiap tantangan yang ada sebagai kesempatan untuk berkembang.',
          img: 'rise.webp',
          ico: 'rise-ico.svg',
        },
        {
          title: 'Show Honest Results',
          desc: 'Berintegritas dan menjunjung tinggi nilai kejujuran dalam setiap hal yang dilakukan.',
          img: 'honest.webp',
          ico: 'honest-ico.svg',
        },
        {
          title: 'Innovation Through Collaboration',
          desc: 'Semangat untuk berkolaborasi untuk menciptakan inovasi yang menjawab tantangan.',
          img: 'collab.webp',
          ico: 'collab-ico.svg',
        },
        {
          title: 'Trust The Team',
          desc: 'Saling terbuka dalam berkomunikasi dan mempercayai anggota tim untuk menciptakan hasil kerja tim yang efektif.',
          img: 'trust.webp',
          ico: 'trust-ico.svg',
        },
      ],
    };
  },
  computed: {
    imgSrcBanner() {
      return this.windowWidth <= 599
        ? '/images/about/bg_about_us_mobile.webp'
        : '/images/about/bg_about_us.webp';
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
    this.checkActiveButton();
  },
  methods: {
    setImg(img) {
      return img ? `/images/about/${img}` : '';
    },
    setErrorImage(event, addClass) {
      this.isErrorImage = true;
      setImageDefault(event, addClass);
    },
    changeYear(year) {
      this.dataSource = REWARDCARDS[`DATA${year}`];
    },
    checkActiveButton() {
      const header = this.$el.querySelector("#button-tab")
      const btns = header.getElementsByClassName("button-tab-year")
      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
          const current = document.getElementsByClassName("active")
          current[0].className = current[0].className.replace(" active", "")
          this.className += " active"
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
