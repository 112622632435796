<template>
  <div 
    v-if="BOARDCONTENTS.DATA.some(boardList => boardList.id === id)"
    class="board"
  >
    <BreadCrumb
      class="top-left"
      :is-use-route-name="true"
      style="margin-bottom:20px;"
    />

    <!-- Content -->
    <div
      v-for="content in BOARDCONTENTS.DATA"
      :key="content.id"
      class="board__wrapper"
    >
      <div v-if="content.id === id" class="board__wrapper__content">
        <div style="margin-top: 20px">
          <div class="board__wrapper__content__name">
            <p>{{ content.name }}</p>
          </div>
          <div class="board__wrapper__content__title">
            <p v-html="content.title" />
          </div>
          <div
            class="board__wrapper__content__desc"
            :class="[classContentWrapper, makeFade]"
            @scroll="cekFade"
          >
            <p id="desc" v-html="content.desc" />
          </div>
        </div>
        <img
          v-show="img"
          class="img"
          :src="setImg(content.imgContent)"
          alt="board.png"
        />
        <img
          v-show="imgBg"
          class="imgBg"
          :src="setImg(content.imgContent)"
          alt="board.png"
        />
      </div>
    </div>
    <!-- Content -->

    <div class="other_lead">
      Pimpinan Kami Lainnya
    </div>
    <!-- Carousel -->
    <div class="carousel">
      <carousel
        id="carousel"
        slider-dot-position="center"
        :show-dot="false"
        :name="carouselName"
        color-slider="black"
        :shimmer="false"
        :total-all-data="BOARDCONTENTS.DATA.length"
        :size="ourBoards.length"
      >
        <template #item>
          <div
            v-for="(slides, index) in ourBoards"
            :id="`${carouselName}-${index + 1}`"
            :key="'board-box-' + index"
          >
            <div v-for="board in slides" :key="board.id">
              <template>
                <div class="board-card" @click="goToDetail(board.id)">
                  <div>
                    <img
                      class="board-img"
                      :src="getImg(board.img)"
                      :alt="board.name + '.png'"
                    />
                  </div>
                  <div class="text_frame">
                    <div class="text_frame__name">
                      <p v-html="board.name" />
                    </div>
                    <div class="text_frame__title">
                      <p v-html="board.shortTitle" />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </carousel>
    </div>
    <!-- Carousel -->
  </div>
  <div v-else>
    <NotFound />
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '../../../misc/mixinMobile';
import mixinMetaInfo from '../../../misc/mixinMetaInfo';
import Carousel from '@/components/new-carousel/Carousel';
import {
  chunkArray,
  generateChunkCarouselResponsive,
  listenerWindowResize,
  MEDIA,
  removeListenerResize,
} from '@/components/new-carousel/util';
import BOARDCONTENTS from '../app/constants/boardContents';
import NotFound from '@/pages/not-found/NotFound.vue';

export default {
  name: 'BoardPage',
  components: {
    BreadCrumb,
    Carousel,
    NotFound,
  },
  mixins: [mixinMobile, mixinMetaInfo],
  props: ['id'],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_about_board_' + this.id , // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      carouselName: 'our-boards',
      isMobileWidth: false,
      img: true,
      imgBg: false,
      makeFade: '',
      classFade: 'fade-bottom',
      classContentWrapper: 'board__wrapper__content__desc',
      BOARDCONTENTS,
    };
  },
  computed: {
    ourBoards() {
      const divideTo = 3;
      const data = [];
      for (const val of this.BOARDCONTENTS.DATA) {
        if (val.id != this.$route.params.id) {
          data.push(val);
        }
      }
      if (this.isMobileWidth) {
        return chunkArray(data.slice(0, 6), 1);
      }
      return chunkArray(data, divideTo);
    },
  },
  created() {
    if (!this.isSsr()) {
      this.isMobile();
      listenerWindowResize(this.isMobile);
    }
  },
  beforeDestroy() {
    removeListenerResize(this.isMobile);
  },
  mounted() {
    this.initFade();
  },
  updated() {
    this.initFade();
  },
  methods: {
    initFade() {
      setTimeout(() => {
        const wrapper = document.querySelector("." + this.classContentWrapper)
        this.makeFade = wrapper.scrollHeight < 218 ? '' : this.classFade
      }, 300)
    },
    setImg(imgContent) {
      if (!this.isSsr()) window.scrollTo(0, 0);
      if (this.isMobileWidth) {
        if (imgContent === 'blank') {
          this.imgBg = true;
          this.img = false;
        } else {
          this.imgBg = false;
          this.img = true;
        }
        return imgContent
          ? `/images/about/board-mobile-${imgContent}.webp`
          : '';
      }

      this.imgBg = false;
      this.img = true;
      return imgContent ? `/images/about/board-${imgContent}.webp` : '';
    },
    getImg(img) {
      return img ? `/images/about/${img}.webp` : '';
    },
    goToDetail(id) {
      this.$router.push(`/about/board/${id}`);
      this.searchMetaInfo = '_about_board_' + id;
      this.getMetaInfo();
    },
    isMobile() {
      if (this.isSsr()) return;
      this.isMobileWidth =
        generateChunkCarouselResponsive(window.innerWidth) === MEDIA().MOBILE;
    },
    cekFade() {
      const wrapper = document.querySelector(`.${this.classContentWrapper}`);
      const end =
        wrapper.scrollHeight - Math.abs(wrapper.scrollTop) <=
        wrapper.clientHeight;
      if (end) {
        wrapper.classList.remove(this.classFade);
      } else {
        wrapper.classList.add(this.classFade);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.other_lead {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-top: -80px;
  margin-bottom: 10px;

  @include for-size(mobile) {
    margin-top: 20px;
  }

  @media (min-width: 600px) and (max-width: 1152px) {
    margin-top: 20px;
  }

  @media (min-width: 1153px) and (max-width: 1280px) {
    margin-top: 0px;
  }
}

.carousel {
  max-width: 665px;
  margin-left: -50px;

  @include for-size(mobile) {
    max-width: 100%;
    margin-left: 0px;
  }

  @media (min-width: 600px) and (max-width: 820px) {
    max-width: 100%;
    margin-left: 0px;
  }
}

.board-card {
  border: 1px;
  border-color: #e8e7e7;
  border-style: solid;
  background: #ffffff;
  border-radius: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  height: 70px;
  width: 180px;
  margin-right: 14px;

  .text_frame {
    margin: auto;

    @include for-size(mobile) {
      margin-right: auto;
    }

    &__name {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;

      @include for-size(mobile) {
        font-size: 10px;
        line-height: 15px;
        margin-right: auto;
      }
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      @include for-size(mobile) {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }

  .board-img {
    margin-top: 10px;
    margin-left: 10px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
  }
}
</style>
