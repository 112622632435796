<template>
  <section>
    <modal
      :header="true"
      :use-icon-close="false"
    >
      <div class="title-wrap">
        <div class="cup">
          <img
            :src="`/images/about/cup.svg`"
            alt="cup"
            width="44"
            height="44"
          >
        </div>
        <span id="title-modal-reward">{{ title }}</span>
        <div
          class="close"
          @click="onClose"
        >
          <img
            :src="`/images/close_modal.svg`"
            alt="close"
            width="12"
            height="12"
          >
        </div>
      </div>
      <ImageLazy
        id="photo-modal-reward"
        :src="photo ? `/images/about/${photo}` : ''"
        alt="reward.webp"
        class="imgReward"
      />
    </modal>
  </section>
</template>

<script>
import Modal from '@/components/new-modal/Modal';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'ModalReward',
  components: { Modal, ImageLazy },
  props: {
    title: {
      type: String,
      default: 'title modal',
    },
    photo: {
      type: String,
      default: 'reward1',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

.title-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include for-size(mobile) {
      margin-bottom: 15px;
    }

    .cup {
      width: 44px;
      display: flex;
      justify-content: center;

      @include for-size(mobile) {
        position: absolute;
      }
    }

    .close {
      width: 36px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      @include for-size(mobile) {
        position: absolute;
        right: 12px;
        height: 36px;
      }
    }
}

#title-modal-reward {
    width: 350px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $color-base-text;
    margin-left: 20px;

    @include for-size(mobile) {
      margin: 60px 10px 0px 10px;
    }
}

::v-deep .modal-lp__content {
    border-radius: 0px !important;
}

::v-deep .modal-lp__content__body {
    padding: 24px;

    @include for-size(mobile) {
      padding: 12px;
    }
}

.imgReward {
    width: 450px;
    margin: auto;

    @include for-size(mobile) {
      width: auto;
    }

    ::v-deep img {
      object-fit: cover;
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }
}
</style>
