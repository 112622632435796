<template>
  <div
    class="board-card"
    @click="goToDetail(id)"
  >
    <div>
      <ImageLazy
        class="board-img"
        :src="img"
        :alt="name + '.webp'"
        @error="(event) => setErrorImage(event, 'board-img')"
      />
    </div>
    <div class="text_frame">
      <div class="text_frame__name">
        {{ name }}
      </div>
      <div class="text_frame__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'BoardCardDetail',
  components: { ImageLazy },
  props: {
    id: {
      required: true,
      default: 0,
    },
    img: {
      type: String,
      required: true,
      default: '',
    },
    name: {
      type: String,
      default: 'Board Name',
    },
    title: {
      type: String,
      default: 'Title',
    },
  },
  methods: {
    goToDetail(id) {
      this.$router.push(`/about/board/${id}`);
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style.scss";

.board-card {
  background: #FFFFFF;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  cursor: pointer;
  width: 230px;
  height: 250px;
  @include for-size(mobile) {
    margin-right: auto;
    width: 150px;
    height: 240px;
  }

  .text_frame {
    margin: auto;
    width: 180px;
    height: 40px;
    @include for-size(mobile) {
      width: 130px;
      margin-right: auto;
    }

    &__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      @include for-size(mobile) {
          font-size: 14px;
          line-height: 21px;
          margin-right: auto;
      }
    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      font-style: italic;
      @include for-size(mobile) {
          font-size: 10px;
          line-height: 15px;
      }
    }
  }

  .board-img {
    border-radius: 50%;
    width: 124px;
    height: 124px;
    margin: auto;

    ::v-deep img {
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }
}

.image-default__board-img {
  font-size: 12px;
  text-align: center;
  overflow: hidden;

  @include image-default(auto, auto);
}
</style>
