<template>
  <div class="reward-card-wrap">
    <div
      class="reward-card"
      @click="eventModal()"
    >
      <ImageLazy
        v-if="post"
        :src="`/images/about/${(isMobileSize ? post.rewardCardsMobileThumb : post.rewardCardsThumbnail) || ''}`"
        alt="reward"
        class="imgReward"
        @error="(event) => setErrorImage(event, 'card-reward')"
      />
      <div
        v-if="post"
        class="info"
      >
        <div class="info-coat">
          <div class="info-wrap">
            <img
              :src="`/images/about/cup.svg`"
              alt="cup"
              width="44"
              height="44"
            >
            <h4>{{ post.rewardCardsTitle }}</h4>
          </div>
        </div>
        <img
          :src="`/images/about/plus.svg`"
          alt="plus"
          width="44"
          height="44"
        >
      </div>
    </div>
    <ModalReward
      v-if="showModal"
      :photo="isMobileSize ? post.rewardCardsMobile : post.rewardCardsPhoto"
      :title="post.rewardCardsTitle"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';
import ModalReward from './ModalReward.vue';
import mixinMobile from '@/misc/mixinMobile';

export default {
  name: 'RewardCard',
  components: { ImageLazy, ModalReward },
  mixins: [mixinMobile],
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {},
  methods: {
    eventModal() {
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    setErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/style.scss";

.reward-card-wrap {
  width: 475px;
  position: relative;
}

.reward-card {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  border: 1px solid $color-white-dark;
  border-radius: 8px;
  padding: 12px;

  @include for-size(mobile) {
    justify-content: space-between;
    height: auto;
  }

  .imgReward {
    width: 120px;
    height: 120px;

    @include for-size(mobile) {
      width: 100%;
      height: 105%;
    }

    ::v-deep img {
      object-fit: cover;
      border-radius: 8px;
      width: 120px;
      height: 120px;

      @include for-size(mobile) {
        display: flex;
        object-position: 50% 0%;
        justify-content: center;
        width: 100%;
        height: 196px;
      }
    }
  }

  .info {
    width: 300px;
    margin-left: 24px;
    display: flex;
    justify-content: space-between;

    @include for-size(mobile) {
      margin-top: 24px;
      width: unset;
      margin-left: unset;
      flex-direction: column;
    }

    .info-coat {
      display: flex;
      align-items: center;

      @include for-size(mobile) {
        display: block;
        align-items: unset;
      }

      .info-wrap{
        width: 250px;

        @include for-size(mobile) {
          width: auto;
        }

        h4 {
          color: black;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

          @include for-size(mobile) {
            margin-top: 4px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.image-default__card-reward {
  padding: 10px;
  text-align: center;

  @include image-default(auto, auto);
}
</style>
